import React from "react";

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Utils from "../utils/Uitls";

const Header = ({ titulo, colorTitulo, espacamentoTituloSubtitulo, subtitulo, colorSubtitulo, children, botaoVoltar, semQuebra, user }) => {

    return <Card sx={{ padding: 2, paddingLeft: 3, marginBottom: 2, }}>
        <Grid container spacing={1} sx={{ alignItems: 'center' }}>

            {botaoVoltar}
            <Grid item xs={semQuebra ? false : 12} md={6} sm={6}>
                <Typography
                    sx={{ fontSize: { xs: 22, sm: 24, md: 26 }, fontWeight: '600' }}
                    color={colorTitulo}
                    gutterBottom={espacamentoTituloSubtitulo}>
                    {titulo}
                </Typography>
                <Typography variant="subtitle2" style={{ color: '#666' }} color={colorSubtitulo}>{subtitulo}</Typography>
                {  Utils.is_semec(user) && Utils.get_zona(user) &&                 
                    <Typography variant="subtitle2" style={{ color: '#666' }} color={colorSubtitulo}>
                        ZONA: { Utils.get_zona(user) }
                    </Typography>
                }
            </Grid>
            {children}
        </Grid>
    </Card>
}

export default Header;
