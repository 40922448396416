import { AppKey } from "../config/GlobalKeys";
import { TokenApi } from '../config/GlobalKeys';
import Dados from "../models/Dados";
import toastr from "toastr";
import { getUser, logout } from "../global/service";
import { TOKEN_APP } from "../global/constants";

export default class ApiRequest {

    static TIMEOUT = 40000;

    static setError(e, obj, msg_error, update) {
        console.log('error', e)
        obj.clear();
        obj.msgError = msg_error;
        obj.error = true;
        update(obj);
    }

    static request(url, options, obj, update, msg_error) {
        ////console.log(loading)
        //let headers = {}

        if (!options.headers) {
            options.headers = {}
            options.headers['Content-type'] = 'application/json'
        }

        try {
            // let user = JSON.parse(window.atob(localStorage.getItem(USER_LOGADO)))
            //  options.headers['token'] = user.token
        } catch (e) {

        }

        try {
            options['signal'] = obj.controller.signal;
        } catch (e) {

        }
        //console.log(url, options);
        obj.loading = true;
        update(obj);
        fetch(url, options).then(
            response => {
                if (response.ok) {
                    response.json()
                        .then(result => {
                            // console.log(result)
                            obj.clear();
                            obj.dados = result;
                            update(obj);

                        })
                        .catch(e => {
                            ApiRequest.setError(e, obj, msg_error, update);
                        })
                } else {

                    //console.log(response)

                    if (response.status === 403) {
                        // toastr.warning('Acesso expirado!')
                        //localStorage.clear()
                        //setTimeout(() => window.location.reload(), 2000);
                    }

                    if (response.status === 410) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 420) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 500) {
                        response.json()
                            .then(result => {
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    //  localStorage.clear()
                                    //  window.location.reload()
                                }
                            }).catch(e => {
                                ApiRequest.setError(e, obj, msg_error, update);
                            })
                        return
                    }

                    ApiRequest.setError(response, obj, msg_error, update);
                }
            }
        ).catch(e => {
            ApiRequest.setError(e, obj, msg_error, update);
        })
    }

    static getParams(obj) {
        let formBody = [];
        for (let property in obj) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(obj[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody;
    }

    static getParamsFormData(obj) {
        let formData = new FormData();
        for (let k in obj) {
            //console.log(obj[k])
            if (obj[k])
                formData.append(k, obj[k]);
        }

        return formData;
    }

    static getParamsFormDataSemFiltrar(obj) {
        let formData = new FormData();
        for (let k in obj) {
            formData.append(k, obj[k]);
        }

        return formData;
    }

    static async requestCadastro(dados, url, success, error, auth = false, upload_arquivo, method= 'post') {
        let formData = null;
        if (!upload_arquivo) {
            formData = ApiRequest.getParams(dados);
        } else {
            formData = dados;
        }

        let headers = { "TokenApi": TokenApi, 'AppKey': AppKey };
        if (!upload_arquivo) {
            headers['Content-type'] = 'application/x-www-form-urlencoded'
            headers['TokenApp'] = TOKEN_APP;
        } else {
            headers['TokenApp'] = TOKEN_APP;
        }
        if (auth) {
            try {

                let user = null
                await getUser((u) => user = u);

                headers['TokenUser'] = user.id + ':' + user.token + ':' + AppKey;
                headers['access'] = user.id + ':' + user.token + ':' + AppKey;
            } catch (e) {

            }
        }

        let controller = new AbortController();

        /* const idTimeout = setTimeout(() => {
            controller.abort();
            error("error");

        }, ApiRequest.TIMEOUT); */

        fetch(url, {
            method,
            headers,
            body: formData,
            signal: controller.signal
        }).then(response => {
            if (response.ok) {
                response.json().then(result => {
                    success(result);
                    //clearTimeout(idTimeout);
                }).catch(e => {
                    if (e.message) {
                        toastr.warning(e.message);
                        error(e.message, true);
                    }
                    //clearTimeout(idTimeout);
                });
            } else {

                //clearTimeout(idTimeout);
                //alert(response.status)
                if (response.status === 500) {
                    response.json()
                        .then(result => {
                            if (result.message) {
                                toastr.warning(result.message);
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    logout();
                                    localStorage.clear()

                                    //  window.location.reload()
                                } else {
                                    error(result.message, true);
                                }
                            }
                        }).catch(e => {
                            if (e.message) {
                                toastr.warning(e.message);
                                error(e.message, true);
                            }
                            ApiRequest.setError(e, new Dados(), "", () => { });
                        })
                    return
                } else if (response.status === 401) {
                    response.json()
                        .then(result => {
                            //alert(JSON.stringify(result.message))
                            if (result.message) {
                                error(result.message, true);
                                toastr.warning(result.message);
                            }
                        }).catch(e => {
                            if (e.message) {
                                toastr.warning(e.message);
                                error(e.message, true);
                            }
                            ApiRequest.setError(e, new Dados(), "", () => { });
                        })
                    return
                }
                error("error");
            }
        }).catch(e => {
            error(e);
            if (e.message) {
                toastr.warning(e.message);
                error(e.message, true);
            }
            //clearTimeout(idTimeout);
        });
    }
    static async requestPdf(dados, url, success, error) {
        let formData = null;
        formData = ApiRequest.getParams(dados);
        // formData = dados;
        let user = null
        await getUser((u) => user = u);
        let headers = {
            "TokenApi": TokenApi,
            'AppKey': AppKey,
            'Content-type': 'application/x-www-form-urlencoded',
            'TokenApp': TOKEN_APP,
            'responseType': 'arraybuffer',
            'emulateJSON': true,
        };

        try {
            headers['TokenUser'] = user.id + ':' + user.token + ':' + AppKey;
            headers['access'] = user.id + ':' + user.token + ':' + AppKey;
        } catch (e) {

        }

        let controller = new AbortController();

        const idTimeout = setTimeout(() => {
            controller.abort();
            error("error");

        }, ApiRequest.TIMEOUT);

        // console.log("valaa", url, formData, headers)
        fetch(url, {
            method: 'post',
            headers,
            body: formData,
            signal: controller.signal
        }).then(response => {
            if (response.ok) {
                response.blob().then(result => {
                    success(result);
                    clearTimeout(idTimeout);
                }).catch(e => {
                    error(e);
                    clearTimeout(idTimeout);
                });
            } else {

                clearTimeout(idTimeout);
                //alert(response.status)
                if (response.status === 500) {
                    response.json()
                        .then(result => {
                            if (result.message === "Token expirado, efetue login novamente!") {
                                logout();
                                //  localStorage.clear()
                                //  window.location.reload()
                            } else {
                                error(result.message, true);
                            }
                        }).catch(e => {
                            ApiRequest.setError(e, new Dados(), "", () => { });
                        })
                    return
                } else if (response.status === 401) {
                    response.json()
                        .then(result => {
                            //alert(JSON.stringify(result.message))
                            error(result.message, true);
                        }).catch(e => {
                            ApiRequest.setError(e, new Dados(), "", () => { });
                        })
                    return
                }

                error("error");
            }
        }).catch(e => {
            error(e);
            clearTimeout(idTimeout);
        });
    }

    static getToken =  async () => {
        let user = null
        await getUser((u) => user = u);
        return user.id + ':' + user.token + ':' + AppKey;
    }

    static async requestAuth(url, options, obj, update, msg_error,) {

        if (!options.headers) {
            const headers = new Headers();
            headers.append("TokenApi", TokenApi)
            headers.append("Content-type", 'application/json')
            headers.append("TokenApp", TOKEN_APP)
            try {
                const token = await ApiRequest.getToken()
                headers.append("TokenUser", token)
            } catch (e) {
            }

            options.headers = headers;
        }

        try {
            options['signal'] = obj.controller.signal;
        } catch (e) {

        }
        obj.loading = true;
        update(obj);


        const idTimeout = setTimeout(() => {
            console.log("cancel request")
            obj.controller.abort();
            ApiRequest.setError("", obj, msg_error, update);

        }, ApiRequest.TIMEOUT);

        fetch(url, options).then(
            response => {
                //console.log(response)
                if (response.ok) {
                    response.json()
                        .then(result => {
                            // console.log(result)
                            obj.clear();
                            obj.dados = result;
                            update(obj, true);
                            clearTimeout(idTimeout);
                        })
                        .catch(e => {
                            ApiRequest.setError(e, obj, e.message || msg_error, update);
                            clearTimeout(idTimeout);
                        })
                } else {

                    //console.log(response)

                    if (response.status === 403) {
                        // toastr.warning('Acesso expirado!')
                        //localStorage.clear()
                        //setTimeout(() => window.location.reload(), 2000);
                    }

                    if (response.status === 410) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 420) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 500) {
                        response.json()
                            .then(result => {
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    logout();
                                    // localStorage.clear();
                                    // window.location.reload();
                                } else {
                                    ApiRequest.setError("", obj, result.message || msg_error, update);
                                    clearTimeout(idTimeout);
                                }
                            }).catch(e => {
                                ApiRequest.setError(e, obj, e.message || msg_error, update);
                                clearTimeout(idTimeout);
                            })
                        return
                    }

                    ApiRequest.setError(response, obj, msg_error, update);
                    clearTimeout(idTimeout);
                }
            }
        ).catch(e => {
            ApiRequest.setError(e, obj, msg_error, update);
            clearTimeout(idTimeout);
        });
    }

    static requestUserAvaliacao(url, options, obj, update, msg_error) {

        let user = null

        if (!options.headers) {
            options.headers = {}
            options.headers['TokenApi'] = TokenApi;
            options.headers['Content-type'] = 'application/x-www-form-urlencoded';
            options.headers['TokenApp'] = TOKEN_APP;

            try {
                options.headers['TokenUser'] = user.id + ':' + user.token + ':' + AppKey;
                options.headers['access'] = user.id + ':' + user.token + ':' + AppKey;
            } catch (e) {
                console.log(e)
            }
        }

        try {
            options['signal'] = obj.controller.signal;
        } catch (e) {

        }
        //console.log(url, options);
        obj.loading = true;
        update(obj);


        const idTimeout = setTimeout(() => {
            console.log("cancel request")
            obj.controller.abort();
            ApiRequest.setError("", obj, msg_error, update);

        }, ApiRequest.TIMEOUT);

        fetch(url, options).then(
            response => {
                //console.log(response)
                if (response.ok) {
                    response.json()
                        .then(result => {
                            // console.log(result)
                            obj.clear();
                            obj.dados = result;
                            update(obj, true);
                            clearTimeout(idTimeout);
                        })
                        .catch(e => {
                            ApiRequest.setError(e, obj, msg_error, update);
                            clearTimeout(idTimeout);
                        })
                } else {

                    //console.log(response)

                    if (response.status === 403) {
                        // toastr.warning('Acesso expirado!')
                        //localStorage.clear()
                        //setTimeout(() => window.location.reload(), 2000);
                    }

                    if (response.status === 410) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 420) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 500) {
                        response.json()
                            .then(result => {
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    logout();
                                    // localStorage.clear();
                                    // window.location.reload();

                                } else if (result.message.includes("Inconsistência detectada ao pesquisar o início")) {
                                    ApiRequest.setError("", obj, "Ops! " + result.message, update);
                                    clearTimeout(idTimeout);
                                } else {
                                    ApiRequest.setError("", obj, msg_error, update);
                                    clearTimeout(idTimeout);
                                }
                            }).catch(e => {
                                ApiRequest.setError(e, obj, msg_error, update);
                                clearTimeout(idTimeout);
                            })
                        return
                    }

                    ApiRequest.setError(response, obj, msg_error, update);
                    clearTimeout(idTimeout);
                }
            }
        ).catch(e => {
            ApiRequest.setError(e, obj, msg_error, update);
            clearTimeout(idTimeout);
        });



    }

    static async requestOld(url, options, loading, msg_error, success, loadingUpdate, formData = false) {
        ////console.log(loading)
        //let headers = {}

        if (!options.headers) {
            options.headers = {}
            options.headers['Content-type'] = 'application/json'
        }

        options.headers['TokenApp'] = TOKEN_APP;

        try {


            let user = null
            await getUser((u) => user = u);
            options.headers['TokenUser'] = user.id + ':' + user.token + ':' + AppKey;
        } catch (e) {
        }
        // options = { ...options, headers }
        loadingUpdate(loading)
        let error = msg_error
        fetch(url, options).then(
            response => {
                if (response.ok) {
                    response.json()
                        .then(result => {

                            success(result, response.status)
                            loading.success()
                            loadingUpdate(loading)

                        })
                        .catch(e => {
                            toastr.error(error)
                            loading.error(error)
                            loadingUpdate(loading)
                            console.log(e)
                        })
                } else {

                    ////console.log(response)

                    if (response.status === 403) {
                        toastr.warning('Acesso expirado!')
                        localStorage.clear()

                        setTimeout(() => window.location.reload(), 2000);
                    }

                    if (response.status === 410) {
                        response.json()
                            .then(result => {
                                toastr.warning(result.message);

                                loading.error(result.message)
                                loadingUpdate(loading)
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 420) {
                        response.json()
                            .then(result => {
                                toastr.warning(result.message);
                                loading.error(result.message)
                                loadingUpdate(loading)
                            }).catch(e => console.log(e))
                        return
                    }



                    if (response.status === 500) {
                        response.json()
                            .then(result => {
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    localStorage.clear()
                                    window.location.reload()
                                } else {
                                    loading.error(result.message)
                                    loadingUpdate(loading)
                                }
                            }).catch(e => {
                                loading.error(error)
                                loadingUpdate(loading)
                                toastr.error(error)
                            })
                        return
                    }

                    loading.error(error)
                    loadingUpdate(loading)
                    toastr.error(error)
                }
            }
        )
            .catch(e => {
                toastr.error(error)
                loading.error(error)
                loadingUpdate(loading)
                console.log(e)
            })
    }
}