import React, { useEffect, useState } from "react";
import { MonthView } from "react-calendar";
import Utils from "../../../utils/Uitls";
import CalendarioServices from "../CalendarioService";
import ItemCalendario from "./ItemCalendario";
import { useDispatch, useSelector } from "react-redux";
import { alteraDataHover } from "../reducer/calendario";
import { ROLES, TIPOS_DESCRITORES } from "../../../global/constants";
import toastr from "toastr";
import "../styles/calendario.css";
import {  checkListaVaziaDescritores } from "../CalendarioHelper";

const MesView = ({
  title,
  startDate,
  descritores,
  diasLetivos,
  mes,
  ano,
  openModal,
  dataMinima,
  dataMaxima,
  permiteAlteracao,
  bloqueios
}) => {
  const [countDiasLetivos, setDiasLetivos] = useState(0);
  const [countSabadoLetivos, setCountSabadoLetivos] = useState(0);

  const { user } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  useEffect(() => {
    let count = 0;
    Object.keys(diasLetivos).forEach((key_data) => {
      let data = CalendarioServices.getDateByDataSTR(key_data);

      if (
        data.getMonth() === mes &&
        data.getFullYear() === ano &&
        ![0, 6].includes(diasLetivos[key_data].dia_da_semana)
      ) {
        count++;
      }
    });
    setDiasLetivos(count);
  }, [diasLetivos, ano, mes]);

  useEffect(() => {
    let count = 0;
    Object.keys(descritores).forEach((key_data) => {
      let data = CalendarioServices.getDateByDataSTR(key_data);
      let mesIgual = data.getMonth() === mes;
      let anoIgual = data.getFullYear() === ano;

      if (mesIgual && anoIgual) {
        count =
          count +
          descritores[key_data].filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.SABADO_LETIVO).length;
      }
    });
    setCountSabadoLetivos(count);
  }, [descritores, ano, mes]);
  
  const isRede = user.perfil === ROLES.PERFIL_GESTOR;
  const isEscola = user.perfil === ROLES.PERFIL_ESCOLA;

  const renderTitleContent = (item) => {
    let date = Utils.formatData2(item.date);
    const dataBloqueada = (isEscola && bloqueios && bloqueios.find((bloq) => (bloq.descritor_inicio.date <= item.date.getTime() && bloq.descritor_fim.date >= item.date.getTime())))

    const onAction = (val, click) => {
      if(dataBloqueada){
        toastr.warning(`Calendário bloqueado entre ${dataBloqueada.descritor_inicio.descricao} e ${dataBloqueada.descritor_fim.descricao}`);
        return
      }

      if (
        dataMinima &&
        dataMaxima &&
        dataMinima.getTime() <= item.date.getTime() &&
        dataMaxima.getTime() >= item.date.getTime() &&
        permiteAlteracao
      ) {
        openModal(date, val, click);
      }
    };

    let permite_alteracao = permiteAlteracao;
    if (permiteAlteracao && checkListaVaziaDescritores(descritores[date])) {
      permite_alteracao = false;
    }

    if (permiteAlteracao && !!descritores[date] && !isRede && !dataBloqueada) {
      permite_alteracao = !!descritores[date].find((desc) => desc.descritor_rede === 0 && desc.id);
    }

    if(!!dataBloqueada){
      permite_alteracao  = false
    }

    // se a data for em um domingo não permite exibir o footer com as opções de editar/remover descritor
    if(item.date.getDay() === 0) {
      permite_alteracao = false
    }

    return (
      <ItemCalendario
        action={onAction}
        descritores={descritores[date]}
        //se existir dia letivo e ele nao for sabado ou domingo
        diaLetivo={![0, 6].includes(diasLetivos[date]?.dia_da_semana) ? diasLetivos[date] : undefined}
        date={item.date}
        permiteAlteracao={permite_alteracao}
      />
    );
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundColor: "#fff",
          padding: 5,
          border: "0.5px solid #dedede",
          borderColor: "#dedede",
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <p style={{ textAlign: "center ", marginBottom: 0, fontWeight: "bold", fontSize: 12 }}>{title}</p>
        <MonthView
          activeStartDate={startDate}
          onMouseOver={(val) => dispatch(alteraDataHover(val))}
          tileContent={renderTitleContent}
          minDate={dataMinima}
          maxDate={dataMaxima}
          valueType="month"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#00bda570",
            borderRadius: 2,
            fontSize: 11,
            padding: "2px 4px",
          }}
        >
          <span>{countDiasLetivos} dias letivos</span>
          <span>{countSabadoLetivos} sábados letivos</span>
        </div>
      </div>
    </>
  );
};

export default MesView;
