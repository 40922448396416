import ApiRequest from "../../../api/ApiRequest";
import * as ApiRoute from "../../../api/ApiRoute";
import { DESCRITOR_ANIVERSARIO_ESCOLA } from "../../../global/constants";
import Dados from "../../../models/Dados";
import { convertDateAniversario, formatDescritorAniversarioParaListagem } from "../CalendarioHelper";
import CalendarioService from "../CalendarioService";
import {
  alteraModeloSelecionado,
  alteraTurnoSelecionado,
  atualizaDescritoresCalendario,
  atualizaDescritoresLegenda,
  atualizaDescritoresParaImportar,
  atualizaFluxoStatus,
  atualizaModelosCalendario,
  atualizaModelosParaImportar,
  atualizaStatusGerais,
  atualizaTurnos,
} from "../reducer/calendario";

export default class CalendarioAPI extends ApiRequest {
  static buscaDescritoresLegenda(dados, ignoreCache, params) {
    return (dispatch) => {
      const callback = (obj) => {
        if (obj.dados) {
          let listaDescritores = obj.dados;
          if(params.isEscola){
            listaDescritores = [
              ...listaDescritores, 
             DESCRITOR_ANIVERSARIO_ESCOLA
            ];
          }
          obj.dados = {
            lista: listaDescritores,
            listaFilter: listaDescritores
              .filter((a) => !!a.legenda_pdf)
              .sort((a, b) => (a.descricao < b.descricao ? -1 : 1)),
          };
        }
        dispatch(atualizaDescritoresLegenda(obj));
      };
      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, inc_regras: 1, limit: 0, ativo: 1, inc_regras: 1 }),
      };

      let url = ApiRoute.GET_DESCRITORES_LEGENDA;
      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível listar as legendas!");
    };
  }

  static buscaDataFundacao(escola_id, ano){
      return new Promise((resolve) => {
         super.requestAuth(
          ApiRoute.GET_FIND_ESCOLAS + "/" + escola_id, 
          {method: 'get'}, 
          new Dados(), 
          res => {
            if(res.dados){
              resolve(convertDateAniversario({data: res.dados.data_fundacao, ano: ano}))
            }
            if(res.error){
              resolve(null)
            }
          }, 
          "Não foi possível buscar data de aniversário"
        );
      })
  }

  static buscaDescritoresCalendario(dados, ignoreCache, params) {
    return (dispatch) => {
      const callback = async (obj) => {
        if (obj && obj.dados) {
          let list = obj.dados;
          if(params.escola_id){
            const data_fundacao = await CalendarioAPI.buscaDataFundacao(params.escola_id, params.ano);
            if(data_fundacao){
              list = [...list, formatDescritorAniversarioParaListagem({data_fim: data_fundacao, data_inicio: data_fundacao})]
            }
          }
          let dados = await CalendarioService.formataInformacoesDescritores(list, params['modelo_trimestre']);
          obj.dados = dados;
        }
        dispatch(atualizaDescritoresCalendario(obj));
      };

      let url = ApiRoute.GET_DESCRITORES_CALENDARIO;
      return super.requestAuth(
        url,
        { method: "POST", body: JSON.stringify({ ...params, limit: 0, ativo: 1 }) },
        dados,
        callback,
        "Não foi possível buscar descritores!"
      );
    };
  }

  static buscaDescritoresParaImportar(dados, ignoreCache, params) {
    return (dispatch) => {
      const callback = async (obj) => {
        dispatch(atualizaDescritoresParaImportar(obj));
      };

      let url = ApiRoute.GET_DESCRITORES_CALENDARIO;
      return super.requestAuth(
        url,
        { method: "POST", body: JSON.stringify({ ...params, limit: 0, ativo: 1 }) },
        dados,
        callback,
        "Não foi possível buscar descritores!"
      );
    };
  }

  static saveAniversario(dados, ignoreCache, params, action){
      return dispatch => {
        return super.requestAuth(
          ApiRoute.ADICIONA_DATA_FUNDACAO + "/" + params.id, 
          {
            method: 'put',
            body: JSON.stringify({...params}),
          }, 
          dados, 
          action, 
          "Não foi possível alterar data de aniversário", 
        );
      }
  }

  static saveAndUpdateDescritor(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.ADICIONA_DESCRITOR;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }

  static removerDescritor(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.REMOVER_DESCRITOR;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }

  static buscaModelosCalendario(dados, ignoreCache, params) {
    return (dispatch) => {
      let url = ApiRoute.GET_MODELOS_CALENDARIO;

      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, limit: 0 }),
      };

      const callback = async (obj) => {
        if (obj && obj.dados && obj.dados.length > 0 && !params.naoSeleciona) {
          let index = params.last ? obj.dados.length - 1 : 0;
          let modelo = obj.dados[index];

          dispatch(alteraModeloSelecionado(modelo.id));
        }

        dispatch(atualizaModelosCalendario(obj));
      };

      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível listar os calendários!");
    };
  }

  static buscaModelosParaImportar(dados, ignoreCache, params) {
    return (dispatch) => {
      let url = ApiRoute.GET_MODELOS_CALENDARIO;

      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, limit: 0 }),
      };

      const callback = async (obj) => {
        dispatch(atualizaModelosParaImportar(obj));
      };

      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível listar os calendários da rede!");
    };
  }

  static importarModelo(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.IMPORTAR_MODELO;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }

  static cadastraNovoModelo(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.CADASTRA_MODELO;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }

  static buscaTurnosCalendario(dados, ignoreCache, params) {
    return (dispatch) => {
      let url = ApiRoute.GET_TURNOS_BY_ESCOLA;

      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, limit: 0 }),
      };

      const callback = async (obj) => {
        if (obj && obj.dados && obj.dados.length > 0) {
          let dados = [];
          obj.dados.forEach((d) => {
            dados.push(d.turno);
          });
          dispatch(alteraTurnoSelecionado(dados[0].sigla));
          obj.dados = dados;
        }
        dispatch(atualizaTurnos(obj));
      };

      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível listar os turnos!");
    };
  }

  static buscaStatusGerais(dados, ignoreCache, params) {
    return (dispatch) => {
      let url = ApiRoute.GET_ALL_STATUS_CALENDARIO;

      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, limit: 0 }),
      };

      const callback = async (obj) => {
        dispatch(atualizaStatusGerais(obj));
      };

      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível listar os status do calendário!");
    };
  }

  static buscaFluxoStatus(dados, ignoreCache, params) {
    return (dispatch) => {
      let url = ApiRoute.GET_FLUXO_STATUS_CALENDARIO;

      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, limit: 0 }),
      };

      const callback = async (obj) => {
        dispatch(atualizaFluxoStatus(obj));
      };

      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível listar os status do calendário!");
    };
  }

  static alteraSituacaoCalendario(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.ATUALIZA_STATUS_CALENDARIO;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }

  static enviaDiasLetivos(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.ENVIA_DIAS_LETIVOS;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }

  static buscaQuantidadeMinimaDiasLetivos(dados, ignoreCache, params, action) {
    return (dispatch) => {
      const success = (res) => {
        action({ success: true, response: res });
      };
      const error = (res) => {
        action({ success: false, response: res });
      };

      let url = ApiRoute.BUSCA_QUANTIDADE_DIAS_LETIVOS;

      return super.requestCadastro({ ...params }, url, success, error, true);
    };
  }
}
