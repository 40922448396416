import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  anoSelecionado: null,
  anos: null,
  // anos: { dados: [{ ano: 2023, status: 1 }] },
  user: null,
  modelo_unico: [],
  modelo_turnos: [],
  descritores: null
};

const globalSlice = createSlice({
  name: "globalReducer",
  initialState: INITIAL_STATE,
  reducers: {
    setUserLogado: (state, action) => {
      if (action.payload === null) state.user = null;
      else state.user = { ...action.payload };
    },
    atualizaAnoSelecionadoGlobal: (state, action) => {
      if (action.payload === null) {
        state.anoSelecionado = null;
      } else {
        state.anoSelecionado = action.payload;
      }
    },
    atualizaListaAnosAtivos: (state, action) => {
      if(action.payload){
        try{
          //adiciona ano 2025, para que as redes comecem a inserir seus calendários.
          const existeAno2025 = action.payload.dados.find(a => a.ano + "" === "2025");
          if(!existeAno2025){
            state.anos = {...action.payload, dados: [...action.payload.dados, { ano: 2025, status: 1, corrente: 0}]}
          }else{
            state.anos = {...action.payload}
          }
        }catch(e){
          state.anos = {...action.payload}
        }
      }else{
        state.anos = null;
      }
    },
    alteraModeloUnico: (state, action) => {
      state.modelo_unico = action.payload;
    },
    alterarModeloTurnos: (state, action) => {
      state.modelo_turnos = action.payload;
    },
    atualizaDescritores: (state, action) => {
      if (action.payload === null) state.descritores = null;
      else state.descritores = { ...action.payload };
    },
  },
});

export const {
  atualizaAnoSelecionadoGlobal,
  atualizaListaAnosAtivos,
  setUserLogado,
  showLoading,
  alteraModeloUnico,
  alterarModeloTurnos,
  atualizaDescritores,
} = globalSlice.actions;

export default globalSlice.reducer;
