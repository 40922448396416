import React from "react";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import LoadingSelect from "./LoadingSelect";

const AutoCompleteCustom = ({ list, noOptionsText, value, onChange, label, disabled, placeholder, campo = 'nome', objResquest = null, required = false }) => {

  let desabilitar = disabled || objResquest?.loading

  return (
    <FormControl fullWidth size="small" autoComplete="off">
      <Autocomplete
        freeSolo
        options={list}
        disabled={desabilitar}
        autoHighlight
        noOptionsText={noOptionsText}
        value={value}
        isOptionEqualToValue={(option, value) => option.id + "" === value.id + ""}
        onChange={(e, value) => onChange(value)}
        getOptionLabel={(option) => option[campo] || "Selecione"}
        popupIcon={<LoadingSelect obj={objResquest} />}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option?.id}>
            {option[campo]}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={label}
            size="small"
            placeholder="Selecione"
            disabled={desabilitar}
            sx={{ background: "#fff" }}
            InputLabelProps={{ shrink: true, fontSize: "1rem" }}
            inputProps={{
              ...params.inputProps,
              autoComplete: placeholder,
              style: { fontSize: "16px" },
            }}
            autoComplete="off"
          />
        )}
      />
    </FormControl>
  );
};

export default AutoCompleteCustom;
