import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../../components/modal/ModalTemplate";
import TextField from "@mui/material/TextField";
import CalendarioServices from "../../CalendarioService";
import Utils from "../../../../utils/Uitls";
import { useSelector } from "react-redux";
import Masked from "vanilla-masker";
import toastr from "toastr";
import { DESCRITOR_ANIVERSARIO_ESCOLA } from "../../../../global/constants";

const Input = styled(TextField)`
  background: #fff;
  border-radius: 4px;
`;

const style = {
  infoBackground: {
    padding: 5,
    backgroundColor: "#d4edda80",
    borderRadius: 5,
  },
  infoBackgroundExcluir: {
    padding: 5,
    backgroundColor: "#426d8a20",
    borderRadius: 5,
  },
};

const ModalItem = ({ open, close, action, type, descritores, loading, descritoresByData }) => {
  const [descritor, setDescritor] = useState();

  const [anoFormacao, setAnoFormacao] = useState('');
  const [dataInicioForm, setDataInicioForm] = useState();
  const [dataFinalForm, setDataFinalForm] = useState();

  const [dataIniStr, setDataIniStr] = useState("");
  const [dataFimStr, setDataFimStr] = useState("");

  const [descricaoAlt, setDescricaoAlt] = useState(null);

  const [isDescritorAniversario, setIsDescritorAniversario] = useState(false);
  const [isDescritorPermiteDescAlt, setIsDescritorPermiteDescAlt] = useState(false);
  const [isDescritorDataUnica, setIsDescritorDataUnica] = useState(false);

  const { data_ini, data_fim } = useSelector((state) => state.calendarioLetivo);

  useEffect(() => {
    if (!open) {
      setDescritor(undefined);
      setDataInicioForm(undefined);
      setDataFinalForm(undefined);
      setIsDescritorAniversario(false)
      setDataIniStr("");
      setDataFimStr("");
      setAnoFormacao("");
    } else {
      if (type === "salvar") {
        setDataIniStr(Utils.formatData2(data_ini));
        setDataFimStr(Utils.formatData2(data_fim));
        setDataInicioForm(Utils.formata_data(data_ini, "dd/MM/yyyy"));
        setDataFinalForm(Utils.formata_data(data_fim, "dd/MM/yyyy"));
      }
    }

    return () => {
      setDescricaoAlt(null);
      setIsDescritorPermiteDescAlt(false);
    }
  }, [open, type]);

  useEffect(() => {
    if (descritoresByData.length > 0 && open) {
      setDescritor(descritoresByData[0].id);
      if (descritoresByData[0].aceita_descricao+"" === "1") {
        setDescricaoAlt(descritoresByData[0].descricao_custom?.toUpperCase());
        setIsDescritorPermiteDescAlt(true);
      } else {
        setDescricaoAlt(null);
        setIsDescritorPermiteDescAlt(false);
      }
    }
  }, [descritoresByData, open]);

  useEffect(() => {
    if (descritores && descritores.length === 1 && open) {
      setDescritor(descritores[0]);
      if (descritores[0]?.descritor?.aceita_descricao+"" === "1") {
        setDescricaoAlt(descritoresByData[0].descricao_custom?.toUpperCase());
        setIsDescritorPermiteDescAlt(true);
      } else {
        setDescricaoAlt(null);
        setIsDescritorPermiteDescAlt(false);
      }
    }
  }, [descritores, open]);

  useEffect(() => {
    if (descritoresByData.length > 0 && descritor && (type === "editar" || type === "excluir")) {
      let desc = descritoresByData.find((d) => d.id === descritor);
      if (desc) {
        setIsDescritorDataUnica(desc.data_unica === 1);
        setDataInicioForm(desc.data_inicio);
        setDataFinalForm(desc.data_fim);
        setDescricaoAlt(null);
        if (desc.aceita_descricao+"" === "1") {
          setDescricaoAlt(desc.descricao_custom?.toUpperCase());
          setIsDescritorPermiteDescAlt(true);
        } else {
          setDescricaoAlt(null);
          setIsDescritorPermiteDescAlt(false);
        }
      }
    }
  }, [descritor, descritoresByData, type]);

  const renderTitleModal = () => {
    switch (type) {
      case "salvar":
        return "Adicionar novo descritor";
      case "editar":
        return "Alterar data do descritor";
      case "excluir":
        return "Remover descritor";
      default:
        return "";
    }
  };

  const RenderSelect = () => {
    return (
      <Autocomplete
        freeSolo
        options={descritores}
        autoHighlight
        noOptionsText={"Nenhum descritor encontrado"}
        value={descritor ? descritor : ""}
        isOptionEqualToValue={(option, value) => option.id + "" === value.id + ""}
        onChange={(event, newValue) => {
          if(newValue && newValue.id === DESCRITOR_ANIVERSARIO_ESCOLA.id){
            setIsDescritorAniversario(true);
          }else{
            setIsDescritorAniversario(false)
          }
          if (newValue && newValue.aceita_descricao && newValue.aceita_descricao+"" === "1") {
            setIsDescritorPermiteDescAlt(true);
          } else {
            setIsDescritorPermiteDescAlt(false);
          }
          setDescricaoAlt(null);
          setDescritor(newValue)
        }}
        getOptionLabel={(option) => option.descricao || ""}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.descricao}
          </Box>
        )}
        renderInput={(params) => (
          <Input
            {...params}
            label="Selecione descritor"
            placeholder="SELECIONE DESCRITOR"
            className="select-form-avalicao"
            sx={{ background: "#fff" }}
            InputLabelProps={{ shrink: true, fontSize: "0.75rem" }}
            inputProps={{
              ...params.inputProps,
              style: { fontSize: "14px" },
            }}
          />
        )}
      />
    );
  };

  const onSubmit = () => {
    if (!!descritor) {
      if (dataInicioForm.length === 10 && dataFinalForm.length === 10) {
        action(
          descritor?.id ? descritor.id : descritor,
          CalendarioServices.getDateByDataSTR(dataInicioForm),
          CalendarioServices.getDateByDataSTR(dataFinalForm),
          descricaoAlt,
          anoFormacao
        );
      } else {
        toastr.warning("Insira data de início e fim de descritor.");
      }
    }
  };

  return (
    <ModalTemplate
      title={renderTitleModal()}
      open={open}
      textConfirm={type === "excluir" && "REMOVER"}
      closeModal={close}
      desabilitarBotao={true}
      habilitarConfirmacao={true}
      onConfirm={onSubmit}
      loading={!!loading}
    >
      {type === "salvar" && (
        <div>
          {dataIniStr === dataFimStr ? (
            <div style={{ ...style.infoBackground }}>
              <p style={{ fontWeight: "bold", marginBottom: 0, fontSize: 14 }}>Data de novo descritor:</p>
              <span style={{ fontSize: 16, fontWeight: "300" }}>{dataIniStr}</span>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ ...style.infoBackground, flex: 1, margin: "0px 5px 0px 0px" }}>
                <p style={{ fontWeight: "bold", marginBottom: 0, fontSize: 14 }}>Data de início para novo descritor:</p>
                <span style={{ fontSize: 16, fontWeight: "300" }}>{dataIniStr}</span>
              </div>
              <div style={{ ...style.infoBackground, flex: 1, margin: "0px 0px 0px 5px" }}>
                <p style={{ fontWeight: "bold", marginBottom: 0, fontSize: 14 }}>Data final para novo descritor:</p>
                <span style={{ fontSize: 16, fontWeight: "300" }}>{dataFimStr}</span>
              </div>
            </div>
          )}
          <FormControl fullWidth style={{ marginTop: 30, marginBottom: '8px' }}>
            <RenderSelect />
            {isDescritorPermiteDescAlt && <TextField
              sx={{ marginTop: '16px' }}
              value={descricaoAlt?.toUpperCase() || ""}
              label="Descrição alternativa (Opcional)"
              variant="outlined"
              onChange={(ev) => {
                if (ev.target.value.toUpperCase().length <= 255) {
                  setDescricaoAlt(ev.target.value.toUpperCase())
                }
              }}
            />}
          </FormControl>
          {isDescritorAniversario && 
            <FormControl fullWidth>
              <TextField
                style={{marginTop: 8}}
                type="number"
                value={anoFormacao}
                label="Informe o ano de fundação"
                variant="outlined"
                onChange={(ev) =>{setAnoFormacao(ev.target.value)}}
              />
            </FormControl>
          }
        </div>
      )}
      {type === "editar" && !!descritor && (
        <div>
          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel id="demo-simple-select-label">Selecione o descritor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={descritor ? descritor : ""}
              label="Selecione o descritor"
              onChange={(val) => setDescritor(val.target.value)}
            >
              {descritoresByData.map((descritor, key) => {
                return (
                  <MenuItem key={key} value={descritor.id}>
                    {descritor.descricao}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {isDescritorDataUnica ? (
            <FormControl fullWidth style={{ marginTop: 30 }}>
              <FormControl fullWidth>
                <TextField
                  value={dataInicioForm}
                  label="Alterar data"
                  variant="outlined"
                  onChange={(ev) => {
                    setDataInicioForm(Masked.toPattern(ev.target.value, "99/99/9999"));
                    setDataFinalForm(Masked.toPattern(ev.target.value, "99/99/9999"));
                  }}
                />
              </FormControl>
            </FormControl>
          ) : (
            <>
              <FormControl fullWidth style={{ marginTop: 30 }}>
                <TextField
                  value={dataInicioForm}
                  label="Data Inicial"
                  variant="outlined"
                  onChange={(ev) => setDataInicioForm(Masked.toPattern(ev.target.value, "99/99/9999"))}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginTop: 30, marginBottom: 30 }}>
                <TextField
                  value={dataFinalForm}
                  label="Data Final"
                  variant="outlined"
                  onChange={(ev) => setDataFinalForm(Masked.toPattern(ev.target.value, "99/99/9999"))}
                />
              </FormControl>
            </>
          )}
          {isDescritorPermiteDescAlt && <FormControl fullWidth>
            <TextField
              value={descricaoAlt?.toUpperCase() || ""}
              label="Descrição alternativa (Opcional)"
              variant="outlined"
              onChange={(ev) => {
                if (ev.target.value.toUpperCase().length <= 255) {
                  setDescricaoAlt(ev.target.value.toUpperCase())
                }
              }} />
          </FormControl>}
        </div>
      )}
      {type === "excluir" && !!descritor && (
        <div>
          {dataInicioForm === dataFinalForm ? (
            <div style={{ ...style.infoBackgroundExcluir }}>
              <p style={{ fontWeight: "bold", marginBottom: 0, fontSize: 14 }}>Data do descritor:</p>
              <span style={{ fontSize: 16, fontWeight: "300" }}>{dataInicioForm}</span>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ ...style.infoBackgroundExcluir, flex: 1, margin: "0px 5px 0px 0px" }}>
                <p style={{ fontWeight: "bold", marginBottom: 0, fontSize: 14 }}>Data de início descritor:</p>
                <span style={{ fontSize: 16, fontWeight: "300" }}>{dataInicioForm}</span>
              </div>
              <div style={{ ...style.infoBackgroundExcluir, flex: 1, margin: "0px 0px 0px 5px" }}>
                <p style={{ fontWeight: "bold", marginBottom: 0, fontSize: 14 }}>Data final descritor:</p>
                <span style={{ fontSize: 16, fontWeight: "300" }}>{dataFinalForm}</span>
              </div>
            </div>
          )}
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="demo-simple-select-label">Selecione o descritor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={descritor ? descritor : ""}
              label="Selecione o descritor"
              onChange={(val) => setDescritor(val.target.value)}
            >
              {descritoresByData.map((descritor, key) => {
                return (
                  <MenuItem key={key} value={descritor.id}>
                    {descritor.descricao}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {isDescritorPermiteDescAlt && (descricaoAlt && descricaoAlt !== "") && <Box sx={{ marginTop: '16px' }}> 
            <span style={{ fontWeight: 600 }}>Descrição:</span> {descricaoAlt}
          </Box>}
        </div>
      )}
    </ModalTemplate>
  );
};

export default ModalItem;
