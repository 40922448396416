import { atualizaDescritores } from "../global/reducer";
import ApiRequest from "./ApiRequest";
import * as ApiRoute from "./ApiRoute";

export default class GlobalAPI extends ApiRequest {
  static buscaDescritores(dados, ignoreCache, params) {
    return (dispatch) => {
      const callback = (obj) => {
        dispatch(atualizaDescritores(obj));
      };
      let options = {
        method: "POST",
        body: JSON.stringify({ ...params, inc_regras: 1, limit: 0, ativo: 1 }),
      };

      let url = ApiRoute.GET_DESCRITORES_LEGENDA;
      return super.requestAuth(url, options, dados, callback, "Ops! Não foi possível obter os descritores!");
    };
  }
}