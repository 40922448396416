import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { } from 'react';
import Utils from '../../utils/Uitls';
import { Table, Td, Thead, Tr } from './Table'
import ObterTextoDescritores from './ObterTextoDescritores';
import { LISTA_FERIADOS } from '../../global/lista_feriados';
import { TIPOS_DESCRITORES } from '../../global/constants';
import { converterParaDate, geraListaDescritoresFeriadoNaoCadastrados } from '../../views/calendario/CalendarioHelper';

const diasDaSemana = {
    0: 'DOM',
    1: 'SEG',
    2: 'TER',
    3: 'QUA',
    4: 'QUI',
    5: 'SEX',
    6: 'SAB',
}

const meses = {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro',
}

const MesCalendario = ({ mes, ano, semanas, countDiasLetivos, countSabadosLetivos, descritores, descritoresMes, par, user }) => {

    const retornaMarcadorDescritorCalendario = (listaDescritores, data) => {
        //deve receber os marcadores dos dias sem feriados, caso tenha feriado cadastrado e nao tenha descritor de feriado registrar o descritor de feriado, caso tenha descritor de feriado e tenha feriado cadastrado no mesmo dia adicionar somente um deles.

        let nova_lista_descritores = [];
        let feriados_relacionados_data = LISTA_FERIADOS[data] ? geraListaDescritoresFeriadoNaoCadastrados(user, LISTA_FERIADOS[data])[0] : null;
        let lista_sem_feriados = listaDescritores ? [...listaDescritores].filter(item => item.tipo_descritor_id !== TIPOS_DESCRITORES.FERIADOS) : null;
        let lista_feriados = listaDescritores ? [...listaDescritores].filter(item => item.tipo_descritor_id === TIPOS_DESCRITORES.FERIADOS): null;
        
        if (lista_sem_feriados) {
            nova_lista_descritores = lista_sem_feriados;
        }

        if ((feriados_relacionados_data && Object.keys(feriados_relacionados_data).length > 0) && (lista_feriados && lista_feriados.length > 0)) {
            nova_lista_descritores.push(feriados_relacionados_data);
        } else {
            if (feriados_relacionados_data && Object.keys(feriados_relacionados_data).length > 0) {
                nova_lista_descritores.push(feriados_relacionados_data);
            } else if (lista_feriados && lista_feriados.length > 0) {
                nova_lista_descritores.push(...lista_feriados);
            }
        }

        if (nova_lista_descritores.length > 0) {
            return <View style={{ flexDirection: 'row' }}>
                {nova_lista_descritores.map((item, key_descritor) => <Td key={key_descritor} styleView={{ flexDirection: 'row', backgroundColor: item.cor, width: '1px', height: '1px', borderRadius: '50%', border: 'none' }}></Td>)}
            </View>
        }
        return <></>
    }

    const geraListaDescritoresMesJuntoFeriados = (descritoresMes) => {
        let lista = [];

        let lista_sem_feriados = [...descritoresMes].filter(item => item.descritor.tipo_descritor_id !== TIPOS_DESCRITORES.FERIADOS);
        let lista_feriados = [...descritoresMes].filter(item => item.descritor.tipo_descritor_id === TIPOS_DESCRITORES.FERIADOS);

        lista = [...lista_sem_feriados]
        
        let feriados_relacionados_mes = {};
        let mes_ano = `${Utils.completaZero(mes + 1)}/${ano}`
        Object.keys(LISTA_FERIADOS).forEach(chave => {
            if (chave.includes(mes_ano)) {
                feriados_relacionados_mes[chave] = geraListaDescritoresFeriadoNaoCadastrados(user, LISTA_FERIADOS[chave]);
            }
        })

        if (Object.keys(feriados_relacionados_mes).length > 0) {
            let lista_mes = [];
            for (const key in feriados_relacionados_mes) {
                lista_mes = [...lista_mes, ...feriados_relacionados_mes[key]];
            }
            lista = [...lista, ...lista_mes];
        }

        if (lista_feriados.length !== 0) {
            let nova_lista_feriados = []

            lista_feriados.forEach(item => {
                //se o descritor de feriado tiver data unica
                if (item.data_inicio === item.data_fim) {
                    // Se o feriado não estiver sido pre-cadastrado na lista de feriados
                    if (!feriados_relacionados_mes[item.data_inicio]) {
                        nova_lista_feriados.push(item); 
                    }
                } else {
                    // Não tem data única
                    for (let index = converterParaDate(item.data_inicio); index <= converterParaDate(item.data_fim); index.setDate(index.getDate() + 1)) {
                        //verificar se tem feriado relacionado e comparar a data, se a data não estiver presente enviar ela para a lista de feriados
                        if (!feriados_relacionados_mes[Utils.formatData2(index)]) {
                            nova_lista_feriados.push({ ...item, data_inicio: Utils.formatData2(index), data_fim: Utils.formatData2(index)}) 
                        }
                    }
                }
            })

            lista = [...lista, ...nova_lista_feriados];
        }
        return lista;
    }

    const getBackgroundColorCell = (data, diaSemana) => {
        let listaDescritores = descritores[data] || []
        const listaFeriados = LISTA_FERIADOS[data] || []

        // exibe o background vermelho nos dias de feriado (exceto se for no domingo)
        if(geraListaDescritoresFeriadoNaoCadastrados(user, listaFeriados)[0] || listaDescritores.find(i => i.tipo_descritor_id === TIPOS_DESCRITORES.FERIADOS) && (diaSemana !== 0)) {
            return '#FF000050'
        }
        return [0, 6].includes(diaSemana) ? '#efefef' : 'transparent' //sab ou dom
    }

    return (
        <View style={{ ...styles.container, flexDirection: par ? 'row-reverse' : 'row' }} break>
            <View style={{ flex: 1.2, flexDirection: 'column' }} >
                <Table>
                    <Thead style={{ borderBottomWidth: 0 }} >
                        <Td styleView={{ padding: 1, backgroundColor: '#03bfa590' }} fontWeight style={{ fontSize: 7 }}>{`${meses[mes]} ${ano}`}<Td style={{fontSize: 6}}>{` (${parseInt(countDiasLetivos) + parseInt(countSabadosLetivos)} dias letivos)`}</Td></Td>
                    </Thead>
                    <Thead>
                        {Object.keys(diasDaSemana).map((mes, key) => <Td styleView={{ padding: 0 }} style={{ fontSize: 6 }} key={key}>{diasDaSemana[mes]}</Td>)}
                    </Thead>
                    {Object.keys(semanas).map((semana, key) => {
                        return (
                            <Tr key={key}>
                                {semanas[semana].map((dia, key) => {
                                    let data_format = Utils.formatData2(dia)
                                    return (
                                        <View key={key} style={{
                                            flex: 1,
                                            borderRightWidth: 1,
                                            borderRightColor: '#dedede',
                                            padding: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            backgroundColor: getBackgroundColorCell(data_format, key) //caso seja sab ou dom, alterar cor
                                        }}>
                                            <Text style={{ fontFamily: 'Times-Bold', fontSize: 6, color: "#000000" }}>{dia ? dia.getDate() : ''}</Text>
                                            {retornaMarcadorDescritorCalendario(descritores[data_format], data_format)}
                                        </View>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Table>
            </View>
            <View style={{ flex: 1, display: "flex", flexDirection: 'column', alignItems: par ? 'flex-end': 'flex-start', marginRight: par ? 5 : 0, marginLeft: par ? 0 : 5 }}>
                <ObterTextoDescritores descritoresMes={geraListaDescritoresMesJuntoFeriados(descritoresMes)} styles={styles} par={par} />
                <View style={{ display: "flex", flexDirection: 'row', marginTop: 5, marginBottom: 0 }} >
                    {countSabadosLetivos && <Text style={{ ...styles.text, fontFamily: 'Times-Bold', fontSize: 6 }}>Sábados letivos: {countSabadosLetivos}</Text>}
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        display: 'flex',
        padding: '2px 5px',
        // border: '1px solid #dedede',
    },
    viewHeader: {
        borderTop: '1px solid #dedede',
        borderRight: '1px solid #dedede',
        borderLeft: '1px solid #dedede',
        backgroundColor: '#dedede'
    },
    title: {
        fontSize: 10,
        fontFamily: 'Times-Bold'
    },
    text: {
        fontSize: 5,
        fontFamily: 'Times-Roman',
        marginBottom: 1
    }

});





export default MesCalendario;