import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Utils from '../../utils/Uitls';
import { hexToRgb } from '@mui/material';

const HeaderPdf = ({ children, header, user }) => {

    return (
        <View fixed style={styles.header}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <View style={styles.viewDadosHeader}>
                    <View>
                        <Image style={styles.logo} source={header.logo} />
                    </View>                 

                    <View>              
                        {header.entidade && <Text style={styles.textHeader}>{header.entidade}</Text>}        
                        {header.orgao && <Text style={styles.textHeader}>{header.orgao}</Text>}
                        {header.dadosEscola && <Text style={styles.textHeader}>{header.dadosEscola}</Text>}
                        {header.anoSelecionado && <Text style={styles.textHeader}>ANO LETIVO: {header.anoSelecionado}</Text>}
                    </View>
                </View>
                { Utils.is_semec(user) && header.zona &&
                    <View style={styles.viewZonaEscola}>                           
                        <Text style={styles.textZona}>
                            { header.zona }
                        </Text>
                    </View>
                }
            </View>
            {children}
        </View>
    );
};

export default HeaderPdf;

const styles = StyleSheet.create({
    header: {
        padding: 8,
    },
    viewDadosHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 6,
        alignItems: 'center'
    },
    viewZonaEscola: {
        display: 'flex',
        justifyContent: 'flex-end', // Alinha a zona da escola à direita
        alignItems: 'flex-end',
        backgroundColor: '#CD5C5C'
    },
    textHeader: {
        fontWeight: 'bold',
        fontSize: 8,
        marginBottom: 2,
        fontFamily: 'Times-Roman'
    },
    textZona: {
        fontWeight: '900',
        fontSize: 8,
        fontFamily: 'Times-Roman',
        padding: '3px',
        color: 'white'
    },
    logo: {
        height: 50,
        width: 35,
        marginRight: 10
    },
});



