import React from 'react'
import { CircularProgress } from '@mui/material';
import { MdCached } from 'react-icons/md';


const LoadingSelect = (props) => {
  const obj = props.obj;
  const showError = !props.noError;

  return (obj && (obj.loading || obj.error) &&
    <>
      {obj.loading && <CircularProgress size={22} sx={{ marginRight: 2 }} />}
      {showError && obj.error && <MdCached
        onClick={obj.action}
        size={22}
        color={"#ff8800"}
        style={{ width: 'min-content', cursor: 'pointer', marginRight: 2 }}
      />}
    </>
  );
}

export default LoadingSelect;