export default class  Dados{
    constructor(dados){
        this.dados = dados || null;
        this.loading = false;
        this.error = false;
        this.msgError = "";
        this.action = ()=>{

        };
        this.controller = new AbortController();
    }

    setAction(action){
        this.action = () => {
            action();            
            this.controller = new AbortController();
        }
    }

    clear(){
        this.loading = false;
        this.error = false;
        this.msgError = "";
    }
}