import React, { useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import RelatorioGreService from "../RelatorioGreService"
import { listaDadosRelatorio } from "../reducer"

import HeaderGlobal from '../../../components/header/HeaderGlobal';
import TabelaEscolasSemestres from "./TabelaEscolasSemestres"
import LoadingData from "../../../components/LoadingData";

const Painel = () => {
    const dispatch = useDispatch()

    const { anoSelecionado } = useSelector(state => state.global)
    const { dadosRelatorio, superintencias } = useSelector(state => state.relatorioGre)

    const requestRelatorios = (descritor_id) => {
        let params = { ano: anoSelecionado, somente_escolas: 1 }
        if(descritor_id) {
            params['descritor_id'] = descritor_id
        }
        RelatorioGreService.buscaRelatorioGres(dispatch, params)
    }

    useEffect(() => {
        if (!superintencias) {
            RelatorioGreService.buscaSuperintencias(dispatch, { ano: anoSelecionado, limit: 0, status: 1 })
        }
    }, [])
    
    useEffect(() => {
        if(anoSelecionado){
            dispatch(listaDadosRelatorio(null))
        }
    }, [anoSelecionado])

    return (
        <>
            <HeaderGlobal titulo={'Relatório de semestres'} />
            <TabelaEscolasSemestres action={requestRelatorios}/>
            {dadosRelatorio && <LoadingData obj={dadosRelatorio} msg="Carregando dados do relatório..." />}
        </>
    )
}

export default Painel;