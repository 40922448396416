import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CalendarioServices from "../../calendario/CalendarioService";
import "../styles/acompanhamento.css";

const ListStatus = ({
  statusSelecionado,
  setStatusSelecionado,
  turnoSelecionado,
  modelos,
  greSelecionado,
  isModeloUnico,
  escolas
}) => {
  const [statusFormatados, setStatusFormatados] = useState([]);

  const { status } = useSelector((state) => state.acompanhamento);

  const quantidadeModeloPorStatus = (modelos, st_id, turno) => {
    if (isModeloUnico) {
      return modelos.filter((m) => (st_id ? m.status_atual_id === st_id : true)).length;
    } else {
      return modelos.filter((m) =>
        st_id
          ? m.status_atual_id === st_id && m[CalendarioServices.getNameTurno(turno)] === 1
          : m[CalendarioServices.getNameTurno(turno)] === 1
      ).length;
    }
  };

  useEffect(() => {
    if (status?.dados && escolas) {
      let statusFormat = Object.keys(status.dados).map((st_id) => {
        st_id = parseInt(st_id);
        let count = quantidadeModeloPorStatus(modelos, st_id, turnoSelecionado);
        return {
          id: parseInt(st_id),
          count,
          ...status.dados[st_id],
        };
      });

      const filtraEscolas = (escola) => {
        if (isModeloUnico) {
          if (greSelecionado) return escola.si_id === greSelecionado;
          else return true;
        } else {
          if (greSelecionado) return escola.si_id === greSelecionado && !!escola[turnoSelecionado];
          else return !!escola[turnoSelecionado];
        }
      };
      
      let listDefault = [
        {
          count: escolas.filter((escola) => filtraEscolas(escola)).length,
          descricao: "Escolas",
          id: 0,
          cor: "#fd7e14",
        },
        {
          count:
          escolas.filter((escola) => filtraEscolas(escola)).length -
            quantidadeModeloPorStatus(modelos, null, turnoSelecionado),
          descricao: "Não iniciou",
          id: -1,
          cor: "#29505e",
        },
      ];
      statusFormat.unshift(...listDefault);
      setStatusFormatados(statusFormat);
    }
  }, [status?.dados, modelos, turnoSelecionado, escolas, greSelecionado]);

  return (
    <div className="div-list-status-acompanhamento">
      {statusFormatados.map((st, key) => (
        <div
          style={{ backgroundColor: st.cor, border: statusSelecionado === st.id ? `2px solid black` : `0px` }}
          onClick={() =>
            setStatusSelecionado((statusAtual) => (statusAtual !== st.id && st.id !== 0 ? st.id : undefined))
          }
          key={key}
          className={`div-status-acompanhamento`}
        >
          <span className="title">{st.count}</span>
          <span className="sub-title">{st.descricao}</span>
        </div>
      ))}
      <div style={{ clear: "both" }}></div>
    </div>
  );
};

export default ListStatus;
